import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 13" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 13</h1>
      <p>Mamma, visste du at jeg er glad i lys?</p>
      <p>Jeg la merke til at det lille juletreet vårt var tomt for lys, så jeg ordnet noen batterier til det. Kanskje det kan lyse opp vår 3. adventsdag i dag?.</p>
      <p>Og, ikke for å være selvsentrert her, men det kan jo lyse for meg - Litjen 4 måneder i dag - også! Hipp hurra for meg!</p>
      <p>For en helg!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
